.App {
    text-align: center;
    background-color: #000;
    min-height: 100vh;
}
.inputs {
    background-color: #151515;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.inputs input {
    font-size: 20px;
    background-color: #252525;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}

.inputs input:focus-visible {
    outline: 1px solid #ff9000;
}

.inputs .download-btn {
    border: 1px solid white;
    font-size: 16px;
    background-color: transparent;
    color: white;
    padding: 7px 30px;
    border-radius: 5px;
    cursor: pointer;
}

.inputs .download-btn:hover {
    border-color: #ff9000;
    color: #ff9000;
}

.result {
    font-family: "Orion Esperanto Dika";
    letter-spacing: -3px;
    font-size: 80px;
    display: inline-block;
    padding: 35px 40px 30px;
    background-color: #151515;
    margin-top: 100px;
}

.result .first {
    color: white;
    margin-right: 10px;
}

.result .second {
    background-color: #ff9000;
    padding: 10px 10px 6px;
    border-radius: 8px;
}

@media (max-width: 700px) {
    .inputs {
        padding: 40px 40px;
    }
    .inputs input,
    .inputs .download-btn {
        flex-basis: 100%;
    }

    .result {
        font-size: 60px;
        letter-spacing: -2px;
    }
}

@media (max-width: 400px) {
    .inputs {
        padding: 20px 20px;
    }

    .result {
        padding: 25px 20px;
        font-size: 40px;
        letter-spacing: -1px;
    }
}
