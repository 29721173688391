@font-face {
    font-family: "Orion Esperanto Dika";
    src: local("Orion Esperanto Dika"),
        url("./font/orion.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0;
    font-family: "Orion Esperanto Dika", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
